<template>
    <section class="position-relative pt-4">
        <cargando v-if="cargandoSolicitudes" />
        <div class="row mx-0 align-items-center px-3 pl-4">
            <div class="col-auto text-general f-15 f-500 d-middle">
                Fecha de análisis
            </div>
            <div class="col-2">
                <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-4">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="btn-general bg-general br-8 cr-pointer text-white px-3" @click="informes">
                    Aplicar
                </div>
            </div>
            <div class="d-middle br-12 border-black ml-auto" style="height:32px;">
                <router-link :to="{name: 'admin.retiros.cambio-precio.lista'}" style="height:32px;width:32px;">
                    <el-tooltip placement="bottom" content="Vista de lista" effect="light">
                        <div class="d-flex align-items-center justify-center br-l-8 cr-pointer h-100 w-100" :class="vista == 1? 'bg-general3 text-white' : 'text-general' " @click="vista = 1">
                            <i class="icon-menu f-15" />
                        </div>
                    </el-tooltip>
                </router-link>
                <router-link :to="{name: 'admin.retiros.cambio-precio.dashboard'}" class="br-r-8" style="height:32px;width:32px;">
                    <el-tooltip placement="bottom" content="Vista de estadistica" effect="light">
                        <div class="d-flex align-items-center justify-center br-r-8 cr-pointer h-100 w-100" :class="vista == 2? 'bg-general3 text-white' : 'text-general' " @click="vista = 2">
                            <i class="icon-metricas f-15" />
                        </div>
                    </el-tooltip>
                </router-link>
            </div>
        </div>
        <div class="row mx-0 mt-4 mx-3 py-3 bg-white br-t-8">
            <p class="col-12 text-general f-600 my-3 px-4">
                Solicitudes de cambio de precio de productos en pedidos
            </p>
            <div class="col-auto px-1" />
            <div class="col-xl-auto col-lg-3">
                <div class="bg-fondo my-1 border f-600 text-general br-20 f-18 br-18 tres-puntos py-2 px-3">
                    <i class="icon-package-variant-closed text-general2 f-18" />
                    {{ agregarSeparadoresNumero(valores.solicitudes) }} Solicitudes
                </div>
            </div>
            <div class="col-xl-auto col-lg-3">
                <div class="bg-fondo my-1 border f-600 text-general br-20 f-18 br-18 tres-puntos py-2 px-3">
                    <i class="icon-leechero-cap text-general2 f-18" />
                    {{ agregarSeparadoresNumero(valores.leecheros) }} {{$config.vendedor}}
                </div>
            </div>
            <div class="col-xl-auto col-lg-3">
                <div class="bg-fondo my-1 border f-600 text-general br-20 f-18 br-18 tres-puntos py-2 px-3">
                    <i class="icon-account text-general2 f-18" />
                    {{ agregarSeparadoresNumero(valores.clientes) }} {{$config.cliente}}
                </div>
            </div>
            <div class="col-xl-auto col-lg-3">
                <div class="bg-fondo my-1 border f-600 text-general br-20 f-18 br-18 tres-puntos py-2 px-3">
                    <i class="icon-currency-usd-circle text-general2 f-18" />
                    {{ separadorNumero(valores.total_descuento) }}
                </div>
            </div>
            <div class="col-xl-auto col-lg-3">
                <div class="bg-fondo my-1 border f-600 text-general br-20 f-18 br-18 tres-puntos py-2 px-3">
                    <i class="icon-sale text-general2 f-18" />
                    {{ agregarSeparadoresNumero(valores.porcentaje_descuento,1) }}% de descuentos
                </div>
            </div>
        </div>
        <!-- Graficas -->
        <graficas
        class="mx-3"
        :aprobados="motivos_aprobados"
        :aprobados-grafica="motivos_aprobados_grafica"
        :rechazados="motivos_rechazados"
        :rechazados-grafica="motivos_rechazados_grafica"
        :finalizados="finalizacion"
        :finalizados-grafica="finalizacion_grafica"
        />
        <!-- Graficas -->
        <div class="row mx-3 bg-white br-8 mt-3">
            <div class="col-12 text-general f-18 f-500 my-4">
                {{$config.vendedor}} que más veces han solicitado cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(leecheros_solicitudes_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in leecheros_solicitudes" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(1)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white py-3 br-8 mt-3">
            <div class="col-12 text-general f-18 f-500 my-4">
                {{$config.vendedor}} que más veces le han aprobado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(leecheros_solicitudes_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in leecheros_solicitudes" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(1)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 mt-3">
            <div class="col-12 text-general f-18 f-500 my-4">
                {{$config.vendedor}} que más veces le han aprobado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(leecheros_aprobados_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in leecheros_aprobados" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(2)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 mt-3 py-3">
            <div class="col-12 text-general f-18 f-500 mb-4">
                {{$config.vendedor}} que más veces le han rechazado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(leecheros_rechazados_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in leecheros_rechazados" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(3)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white py-3 mt-3 br-8">
            <div class="col-12 text-general f-18 f-500 mb-4">
                Productos que más veces le han aprobado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(productos_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            Productos
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in productos" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(4)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 py-3 mt-3">
            <div class="col-12 text-general f-18 f-500 mb-4">
                {{$config.vendedor}} que más veces le han rechazado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(leecheros_rechazados_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in leecheros_rechazados" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(3)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 py-3 mt-3">
            <div class="col-12 text-general f-18 f-500 mb-4">
                Productos que más veces le han aprobado una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:120px;height:117px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center f-16 mt-3">
                            {{ agregarSeparadoresNumero(productos_total) }}
                        </p>
                        <p class="text-general text-center f-16">
                            Productos
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,s) in productos" :key="s" class="col-2 tres-puntos">
                        <div class="row mx-0 justify-center">
                            <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                <el-button>
                                    <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                </el-button>
                            </el-tooltip>
                        </div>
                        <p class="text-general text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:120px;height:117px;" @click="verMas(4)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 py-3 mt-3">
            <div class="col-12 text-general f-18 f-500 my-4">
                Categorias que más veces han solicitado cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center" style="width:114px;height:79px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general px-2 text-center tres-puntos f-16">
                            {{ agregarSeparadoresNumero(categorias_total) }}
                        </p>
                        <p class="text-general text-center tres-puntos f-16">
                            Categorias
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,cat) in categorias" :key="cat" class="col-2">
                        <div class="row mx-0 justify-center">
                            <div class="br-12 bg-light-f5 border d-middle-center f-14 tres-puntos" style="width:117px;height:85px;">
                                <p class="tres-puntos">{{ data.nombre }}</p>
                            </div>
                        </div>
                        <p class="text-general px-3 text-center f-600 my-1 tres-puntos">
                            {{ separadorNumero(data.total_descuento) }}
                        </p>
                        <p class="text-general px-3 text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                        </p>
                        <p class="text-general px-3 text-center f-16 f-400 my-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:114px;height:79px;" @click="verMas(5)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-8 py-3 mt-3">
            <div class="col-12 text-general f-18 f-500 mb-4">
                {{$config.cliente}} que más veces han solicitado cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center " style="width:116px;height:62px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="text-general text-center tres-puntos f-16 px-2">
                            {{ agregarSeparadoresNumero(clientes_total) }} Clientes
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,cli) in clientes" :key="cli" class="col-2 d-flex justify-center">
                        <div class="br-12 border f-14 tres-puntos" style="width:125px;min-height:272px;">
                            <p class="tres-puntos text-general f-16 border-bottom px-2 py-3 text-center">
                                {{ data.nombre }}
                            </p>
                            <p class="text-general text-center tres-puntos px-2 f-16 f-600 my-2">
                                {{ separadorNumero(data.total_descuento) }}
                            </p>
                            <p class="text-general text-center f-16 f-600 my-2">
                                {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                            </p>
                            <p class="text-general text-center f-16 f-600 my-2">
                                {{ agregarSeparadoresNumero(data.cantidad) }}
                            </p>
                            <div class="row mx-0 justify-center">
                                <img :src="data.ultimo_leechero.foto" class="obj-cover rounded-circle" style="widht:80px;height:80px;" />
                            </div>
                            <p class="text-general text-center px-2 tres-puntos f-16 f-600 my-2">
                                {{ data.ultimo_leechero.nombre }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-center br-12 text-white text-center" style="width:116px;height:62px;" @click="verMas(6)">
                    <p>
                        Ver más
                    </p>
                    <i class="icon-angle-right" />
                </div>
            </div>
        </div>
        <div class="row mx-3 mt-3 bg-white br-8 py-3">
            <div class="col-12 text-general f-18 f-500 mb-4">
                Operadores que más veces le han aprobaron una solicitud de cambio de precio
            </div>
            <div class="col-2">
                <div class="row mx-0 justify-content-end">
                    <div class="bg-grey br-12 d-flex flex-column justify-center " style="width:120px;height:105px;">
                        <p class="text-center text-general f-18 f-600">
                            Total
                        </p>
                        <p class="f-16 px-2 text-general text-center tres-puntos">
                            {{ agregarSeparadoresNumero(admins_total) }}
                        </p>
                        <p class="text-general text-center tres-puntos ">
                            Operadores
                        </p>
                    </div>
                </div>
                <p class="text-right f-16 my-1 f-400">
                    Valor Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Descuento Solicitado
                </p>
                <p class="text-right f-16 my-1 f-400">
                    Solicitudes
                </p>
                <p class="text-right f-16 mt-3 f-400">
                    Porcentaje de aprobación
                </p>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data,adm) in admins" :key="adm" class="col-2 d-flex justify-center">
                        <div class="br-12 border f-14 tres-puntos" style="width:125px;min-height:272px;">
                            <div class="row mx-0 justify-center">
                                <el-tooltip :content="data.nombre" placement="bottom" effect="light">
                                    <el-button>
                                        <img :src="data.foto" class="br-12" style="width:120px;height:117px;" />
                                    </el-button>
                                </el-tooltip>
                            </div>
                            <p class="text-general text-center f-16 my-2 f-600">
                                {{ separadorNumero(data.total_descuento) }}
                            </p>
                            <p class="text-general text-center f-16 f-500 my-1">
                                {{ agregarSeparadoresNumero(data.porcentaje_descuento,1) }}%
                            </p>
                            <p class="text-general text-center f-16  border-bottom">
                                {{ agregarSeparadoresNumero(data.cantidad) }}
                            </p>
                            <p class="text-general mt-3 f-16 text-center f-600">
                                {{ agregarSeparadoresNumero(data.porcentaje_aprobado,1) }}%
                            </p>
                            <div class="row mx-1 my-1 bg-light-f5 br-12" style="height:5px;">
                                <div class="bg-green br-12 h-100" :style="`width:${data.porcentaje_aprobado}%`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-white cr-pointer d-flex flex-column justify-center br-12" style="width:116px;height:62px;" />
            </div>
        </div>
        <!-- Partials -->
        <modal-leecheros-precio ref="modalLeecherosPrecio" />
    </section>
</template>

<script>
import SugerenciasPrecios from "~/services/cambio_precio_estadisticas";
import moment from 'moment'
import { mapGetters } from 'vuex'
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];

export default {
    components: {
        modalLeecherosPrecio: () => import('../partials/modalLeecherosPrecio'),
        graficas: ()=> import('./components/graficas')
    },
    data(){
        return {
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            vista: 2,
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            cargandoSolicitudes: true,
            value: '',
            value1: '',
            url_persona: 'https://cdnb.20m.es/sites/112/2019/04/cara6-620x618.jpg',
            leecheros_solicitudes_total: 0,
            leecheros_aprobados_total: 0,
            leecheros_rechazados_total: 0,
            productos_total: 0,
            categorias_total: 0,
            clientes_total: 0,
            admins_total: 0,
            valores: {
                solicitudes: 0,
                leechero: 0,
                clientes: 0,
                total_descuento: 0,
                porcentaje_descuento: 0,
            },
            motivos_aprobados: [],
            motivos_aprobados_grafica: [],
            motivos_rechazados: [],
            motivos_rechazados_grafica: [],
            finalizacion: [],
            finalizacion_grafica: [],
            leecheros_solicitudes: [],
            leecheros_aprobados: [],
            leecheros_rechazados: [],
            productos: [],
            categorias: [],
            clientes: [],
            admins: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return

            this.cargando()
            this.montar()
        },
        cargandoSolicitudes(val){
            this.$store.commit('cedis/setCargandoTerminado', !val)
        }
    },
    mounted(){
        this.$store.commit('cedis/setCargandoTerminado', false)

        this.montar()
    },
    methods: {
        verMas(tipo){
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                id_moneda: this.id_moneda,
                id_cedis: this.id_cedis,
                tipo
            }
            this.$refs.modalLeecherosPrecio.toggle(params);
        },
        cargando(){
            this.cargandoSolicitudes = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.dashboardSugerenciasPrecios()
                    }, 1000);
                }else{
                    await this.dashboardSugerenciasPrecios()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },
        async dashboardSugerenciasPrecios(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await SugerenciasPrecios.dashboardSugerenciasPrecios(params)

                this.leecheros_solicitudes_total = data.leecheros_solicitudes_total
                this.leecheros_aprobados_total = data.leecheros_aprobados_total
                this.leecheros_rechazados_total = data.leecheros_rechazados_total
                this.productos_total = data.productos_total
                this.categorias_total = data.categorias_total
                this.clientes_total = data.clientes_total
                this.admins_total = data.admins_total
                this.valores = data.valores
                this.leecheros_solicitudes = data.leecheros_solicitudes
                this.leecheros_aprobados = data.leecheros_aprobados
                this.leecheros_rechazados = data.leecheros_rechazados
                this.productos = data.productos
                this.categorias = data.categorias
                this.clientes = data.clientes
                this.admins = data.admins

                let finalizacion_local = []
                let motivos_aprobados_local = []
                let motivos_rechazados_local = []
                this.finalizacion = data.finalizacion
                this.motivos_aprobados = data.motivos_aprobados
                this.motivos_rechazados = data.motivos_rechazados

                let finalizacion = data.finalizacion
                for (var i = 0; i < finalizacion.length; i++){
                    finalizacion_local.push({
                        value: finalizacion[i].cantidad,
                        name: finalizacion[i].nombre,
                        percent: finalizacion[i].porcentaje_descuento,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.finalizacion_grafica = data.finalizacion
                this.finalizacion = finalizacion_local

                let motivos_aprobados = data.motivos_aprobados
                for (var i = 0; i < motivos_aprobados.length; i++){
                    motivos_aprobados_local.push({
                        value: motivos_aprobados[i].cantidad,
                        name: motivos_aprobados[i].nombre,
                        percent: motivos_aprobados[i].porcentaje_descuento,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.motivos_aprobados_grafica = data.motivos_aprobados
                this.motivos_aprobados = motivos_aprobados_local

                let motivos_rechazados = data.motivos_rechazados
                for (var i = 0; i < motivos_rechazados.length; i++){
                    motivos_rechazados_local.push({
                        value: motivos_rechazados[i].cantidad,
                        name: motivos_rechazados[i].nombre,
                        percent: motivos_rechazados[i].porcentaje_descuento,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.motivos_rechazados_grafica = data.motivos_rechazados
                this.motivos_rechazados = motivos_rechazados_local


                // this.sugerencias = data.sugerencias
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoSolicitudes = false
            }
        },
        async informes(){
            try {
                this.cargando()

                await this.dashboardSugerenciasPrecios()

            } catch (error){
                console.log(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
.br-l-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-r-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.h:hover{
    transition: 0.2s;
    background-color: var(--text-general);
    color: #ffffff;
}
.bg-grey{background-color: #EEEEED;}
</style>
