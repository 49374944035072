var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-2"},[(_vm.dataTable.length)?_c('div',{staticClass:"row mx-0 px-3 py-3"},[_c('div',{staticClass:"col bg-white br-8 py-4"},[_c('div',{staticClass:"row align-items-center mx-0"},[_c('div',{staticClass:"col-auto text-general f-18 f-500"},[_vm._v(" Retiros sin confirmar ")]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"bg-fondo text-general br-8 py-3 br-8 px-3"},[_vm._v(" Hace más de una semana "),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.masUnaSemana)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"bg-fondo text-general br-8 py-3 br-8 px-3"},[_vm._v(" Hace una semana "),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.haceUnaSemana)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"bg-fondo text-general br-8 py-3 br-8 px-3"},[_vm._v(" Ayer "),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.ayer)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"bg-fondo text-general br-8 py-3 br-8 px-3"},[_vm._v(" Hoy "),_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.hoy)+" ")])])])])])]):_vm._e(),_c('div',{staticClass:"row mx-0"},[(_vm.dataTable.length)?_c('div',{staticClass:"col-12"},[_c('tabla-general',{attrs:{"alto":"calc(100vh - 518px)","data":_vm.dataTable,"activar-seleccion":true},on:{"seleccion":_vm.abrirModal}},[_c('template',{slot:"cabecera-izquierda"},[_c('p',{staticClass:"col-auto py-3 text-general f-18 f-600"},[_vm._v("Lista de retiros de productos desde "+_vm._s(_vm.$config.vendedor))])]),_c('template',{slot:"adicionales-derecha"},[[_c('el-table-column',{attrs:{"label":"","class-name":"f-16 text-general text-center","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"f-20 text-orange",class:{ 'icon-format-list-checks' : row.estado == 2}})]}}],null,false,3999386029)}),_c('el-table-column',{attrs:{"prop":"tipo","label":"Tipo","class-name":"f-16 text-general text-center","width":"125"}}),_c('el-table-column',{attrs:{"prop":"producto_desc","label":"Producto","class-name":"f-16 text-general text-left","width":"500"}}),_c('el-table-column',{attrs:{"label":"Cantidad","class-name":"f-16 text-general text-right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.agregarSeparadoresNumero(row.cantidad))+" ")])]}}],null,false,604575983)}),_c('el-table-column',{attrs:{"label":"Valor","class-name":"f-16 text-general text-center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.total))+" ")])]}}],null,false,3502665966)}),_c('el-table-column',{attrs:{"label":"Fecha Creación","class-name":"f-16 text-general text-center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatearFecha(row.created_at))+" ")])]}}],null,false,1429107865)}),_c('el-table-column',{attrs:{"prop":"origen","label":_vm.$config.vendedor,"class-name":"f-16 text-general text-left","width":"200"}}),_c('el-table-column',{attrs:{"prop":"cedis_destino","label":"Destino","class-name":"f-16 text-general text-left","width":"200"}})]],2)],2)],1):_c('div',{staticClass:"col-12"},[_c('sin-datos',{attrs:{"icon":"list","mensaje":"No hay datos para mostrar."}})],1)]),_c('modal-informacion-compra',{ref:"modalInformacionCompra",on:{"listar":_vm.retirosAbiertos}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }