import axios from 'axios'

const API_PREFIX = "retiros";

const ENDPOINTS = {
    retirosAbiertos: params => axios(`${API_PREFIX}/abiertos`, { params }),
    retirosAprobados: params => axios(`${API_PREFIX}/aprobados`, { params }),
    infoModalRetiro: (id,params) => axios(`${API_PREFIX}/info-modal-retiro/${id}`, { params }),
    gestionRetiro: (id,params) => axios.put(`${API_PREFIX}/gestion-retiro/${id}`, params),

    crear_comision: params => axios.post(`${API_PREFIX}/comision`, params),
    editar_comision: params =>
        axios.put(`${API_PREFIX}/${params.id}/comision`, params)
};

export default ENDPOINTS
