import axios from 'axios'

const API_PREFIX = "cambio-precio";

const ENDPOINTS = {
    tablaSugerenciasPrecios: params => axios(`${API_PREFIX}/tabla`, { params }),
    dashboardSugerenciasPrecios: params => axios(`${API_PREFIX}/dashboard`, { params }),
    verMasSugerenciasPrecios: params => axios(`${API_PREFIX}/ver-mas-sugerencia-precios`, { params })
};

export default ENDPOINTS
